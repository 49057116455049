import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(120deg, #2d3436, #3498db); /* Use brand colors for background */
  margin: 0;
`;

const Card = styled.div`
  background: #ffffff;
  border-radius: 20px;
  padding: 40px;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
`;

const Heading = styled.h2`
  font-size: 2rem;
  margin-bottom: 10px;
  color: #2d3436; /* Updated to brand's dark text color */
  font-weight: 700;
`;

const Message = styled.p`
  font-size: 1rem;
  margin-bottom: 20px;
  color: #555;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
  text-align: left;
`;

const Label = styled.label`
  font-size: 0.9rem;
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  color: #7f8c8d; /* Use a lighter gray color for labels */
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1rem;
  background: #f4f6f8; /* Lighter background for inputs */
  color: #2d3436; /* Match text color */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;

  &:focus {
    outline: none;
    border-color: #3498db; /* Focus color matching the brand */
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
    background: #ffffff;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  background: #3498db; /* Brand color for button */
  color: #ffffff;
  font-size: 1rem;
  font-weight: 700;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;

  &:hover {
    background: #2980b9; /* Darker shade on hover */
    transform: translateY(-3px);
  }

  &:active {
    transform: translateY(0);
  }
`;

const ErrorMessage = styled.p`
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 10px;
`;

const SuccessMessage = styled.p`
  color: #28a745;
  font-size: 0.9rem;
  margin-top: 10px;
`;

const StyledLink = styled.a`
  color: #3498db; /* Primary brand color for links */
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ResetPassword = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post('https://limitincrease.xyz/api/reset-password', {
        token,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      setMessage(response.data.message);
    } catch (err) {
      setError(err.response.data.detail);
    }
  };

  return (
    <Container>
      <Card>
        <Heading>Reset Password</Heading>
        <form onSubmit={handleSubmit}>
          <FormGroup>
            <Label>New Password:</Label>
            <Input 
              type="password" 
              value={newPassword} 
              onChange={(e) => setNewPassword(e.target.value)} 
              required 
            />
          </FormGroup>
          <FormGroup>
            <Label>Confirm Password:</Label>
            <Input 
              type="password" 
              value={confirmPassword} 
              onChange={(e) => setConfirmPassword(e.target.value)} 
              required 
            />
          </FormGroup>
          <Button type="submit">Reset Password</Button>
        </form>
        {message && <SuccessMessage>{message}</SuccessMessage>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Card>
    </Container>
  );
};

export default ResetPassword;
