import React, { useState, useEffect } from 'react';
import { FaCopy } from 'react-icons/fa';
import axios from 'axios';
import styled from 'styled-components';
import { Modal, Button, TextField, DialogActions, DialogTitle, DialogContent, Dialog } from '@mui/material';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Styled Components for overall layout
const WalletContainer = styled.div`
  margin: 20px auto;
  max-width: 900px;
  padding: 20px;
  background: #121212;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
`;

const WalletTitle = styled.h3`
  text-align: center;
  font-size: 1.8rem;
  color: #00ff99;
  margin-bottom: 20px;
`;

const WalletGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`;

const WalletCard = styled.div`
  background: #1e1e1e;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const CopyButton = styled.button`
  margin-top: 10px;
  background-color: #00ff99;
  color: #121212;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;

  &:hover {
    background-color: #00cc7a;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 10px;  /* Add space between buttons */
  margin-top: 10px;  /* Optional: Adjust space above buttons */
`;

const StyledButton = styled(Button)`
  margin: 5px;
`;

const Wallets = () => {
  const [walletDetails, setWalletDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [openSendDialog, setOpenSendDialog] = useState(false);
  const [openReceiveDialog, setOpenReceiveDialog] = useState(false);
  const [sendAmount, setSendAmount] = useState('');
  const [sendAddress, setSendAddress] = useState('');
  const [coinBalance, setCoinBalance] = useState(0);
  const [selectedCoin, setSelectedCoin] = useState(null);

  const fetchWalletDetails = async () => {
    try {
      const token = localStorage.getItem('token');

      if (!token) {
        setError('User is not authenticated.');
        setLoading(false);
        return;
      }

      const response = await axios.get('https://limitincrease.xyz/api/wallet', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setWalletDetails(response.data);
    } catch (err) {
      setError('An error occurred while fetching wallet details.');
    } finally {
      setLoading(false);
    }
  };

  const handleSend = () => {
    if (parseFloat(sendAmount) <= 0) {
      alert('Amount must be greater than zero!');
    } else if (parseFloat(sendAmount) > coinBalance) {
      alert('Insufficient balance!');
    } else {
      alert('Transaction in progress...');
      // Add API request to send crypto here
      setOpenSendDialog(false);
    }
  };

  const handleCopy = (address) => {
    alert('Address copied to clipboard!');
  };

  useEffect(() => {
    fetchWalletDetails();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <WalletContainer>
      <WalletTitle>My Wallets</WalletTitle>
      {walletDetails && walletDetails.wallet_info && walletDetails.wallet_info.length > 0 ? (
        <WalletGrid>
          {walletDetails.wallet_info.map((wallet, index) => (
            <WalletCard key={index}>
              <div>
                <strong>Coin:</strong> {wallet.coin}
              </div>
              <div>
                <strong>Network:</strong> {wallet.network}
              </div>
              <div>
                <strong>Balance:</strong> {wallet.balance}
              </div>
              <div>
                <CopyToClipboard text={wallet.address} onCopy={() => handleCopy(wallet.address)}>
                  <CopyButton>
                    <FaCopy /> Copy Address
                  </CopyButton>
                </CopyToClipboard>
              </div>
              <ButtonContainer>
                <StyledButton variant="contained" color="primary" onClick={() => { setSelectedCoin(wallet); setOpenSendDialog(true); }}>
                  Send
                </StyledButton>
                <StyledButton variant="contained" color="secondary" onClick={() => { setSelectedCoin(wallet); setOpenReceiveDialog(true); }}>
                  Receive
                </StyledButton>
              </ButtonContainer>
            </WalletCard>
          ))}
        </WalletGrid>
      ) : (
        <p>No wallets found.</p>
      )}

      {/* Send Coin Dialog */}
      <Dialog open={openSendDialog} onClose={() => setOpenSendDialog(false)}>
        <DialogTitle>Send {selectedCoin?.coin}</DialogTitle>
        <DialogContent>
          <TextField
            label="Amount"
            value={sendAmount}
            onChange={(e) => setSendAmount(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            inputProps={{ min: 0 }}
          />
          <TextField
            label="Recipient Address"
            value={sendAddress}
            onChange={(e) => setSendAddress(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setOpenSendDialog(false)} color="secondary">
            Cancel
          </StyledButton>
          <StyledButton onClick={handleSend} color="primary" disabled={parseFloat(sendAmount) <= 0}>
            Send
          </StyledButton>
        </DialogActions>
      </Dialog>

      {/* Receive Coin Dialog */}
      <Dialog open={openReceiveDialog} onClose={() => setOpenReceiveDialog(false)}>
        <DialogTitle>Receive {selectedCoin?.coin}</DialogTitle>
        <DialogContent>
          <p>Use the address below to receive {selectedCoin?.coin}:</p>
          <TextField
            value={selectedCoin?.address}
            fullWidth
            margin="normal"
            readOnly
            InputProps={{
              endAdornment: (
                <StyledButton onClick={() => handleCopy(selectedCoin?.address)} color="primary">
                  Copy
                </StyledButton>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <StyledButton onClick={() => setOpenReceiveDialog(false)} color="secondary">
            Close
          </StyledButton>
        </DialogActions>
      </Dialog>
    </WalletContainer>
  );
};

export default Wallets;
