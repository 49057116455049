import React, { useState } from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

const ContactSupport = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) errors.email = 'Valid email is required';
    if (!formData.subject) errors.subject = 'Subject is required';
    if (!formData.message) errors.message = 'Message is required';
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      alert('Form submitted successfully!');
      // Handle form submission here
    }
  };

  return (
    <Container>
      <Title>Contact Support</Title>
      <Description>
        Have questions or need assistance? Reach out to us using the form below, and we'll be happy to help.
      </Description>

      <ContactInfo>
        <ContactDetails>
          <ContactDetail>
            <FaEnvelope size={24} color="#0d47a1" />
            <DetailText>Email: <span>support@limitincrease.xyz</span></DetailText>
          </ContactDetail>
          <ContactDetail>
            <FaPhoneAlt size={24} color="#0d47a1" />
            <DetailText>Phone: <span>+1-213-612-854</span></DetailText>
          </ContactDetail>
        </ContactDetails>

        <Form onSubmit={handleSubmit}>
          <FormField>
            <Label htmlFor="name">Your Name</Label>
            <Input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              error={formErrors.name}
              placeholder="Enter your name"
            />
            {formErrors.name && <Error>{formErrors.name}</Error>}
          </FormField>

          <FormField>
            <Label htmlFor="email">Your Email</Label>
            <Input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={formErrors.email}
              placeholder="Enter your email"
            />
            {formErrors.email && <Error>{formErrors.email}</Error>}
          </FormField>

          <FormField>
            <Label htmlFor="subject">Subject</Label>
            <Input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              error={formErrors.subject}
              placeholder="Enter the subject"
            />
            {formErrors.subject && <Error>{formErrors.subject}</Error>}
          </FormField>

          <FormField>
            <Label htmlFor="message">Message</Label>
            <TextArea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              error={formErrors.message}
              placeholder="Write your message here"
            />
            {formErrors.message && <Error>{formErrors.message}</Error>}
          </FormField>

          <SubmitButton type="submit">Send Message</SubmitButton>
        </Form>
      </ContactInfo>
    </Container>
  );
};

export default ContactSupport;

// Styled Components

const Container = styled.div`
  padding: 60px 20px;
  background-color: #f9fbfd;
  max-width: 900px;
  margin: 40px auto;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: 700;
  color: #0d47a1;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 18px;
  color: #555;
  line-height: 1.8;
  margin-bottom: 40px;
`;

const ContactInfo = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  padding: 40px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
`;

const ContactDetails = styled.div`
  margin-bottom: 40px;
`;

const ContactDetail = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const DetailText = styled.p`
  font-size: 16px;
  color: #333;
  margin-left: 10px;

  span {
    font-weight: bold;
    color: #0d47a1;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const FormField = styled.div`
  text-align: left;
`;

const Label = styled.label`
  font-size: 16px;
  color: #333;
  margin-bottom: 8px;
  display: block;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.error ? '#ff4d4f' : '#ccc')};
  border-radius: 8px;
  font-size: 16px;
  outline: none;

  &:focus {
    border-color: #0d47a1;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 12px;
  border: 1px solid ${(props) => (props.error ? '#ff4d4f' : '#ccc')};
  border-radius: 8px;
  font-size: 16px;
  outline: none;
  min-height: 150px;

  &:focus {
    border-color: #0d47a1;
  }
`;

const Error = styled.span`
  color: #ff4d4f;
  font-size: 14px;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: #0d47a1;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #093874;
  }
`;
