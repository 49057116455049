import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Grid, Typography, Button, Box, Avatar, Tab, Tabs } from "@mui/material";
import { FaExchangeAlt, FaWallet, FaPiggyBank, FaBolt } from "react-icons/fa";
import { ThemeProvider } from "@mui/system";
import { useTheme } from "@mui/material/styles";
import { keyframes } from "@emotion/react";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const LandingPage = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedTab, setSelectedTab] = useState('Exchange'); // Track selected tab for image change

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          background: "linear-gradient(135deg, #0f0f0f, #1e1e1e)",
          minHeight: "100vh",
          color: "white",
          fontFamily: "Roboto, sans-serif",
          textAlign: "center",
        }}
      >
        {/* Hero Section */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            padding: { xs: "3rem 1rem", sm: "4rem 2rem" },
            minHeight: "60vh",
            background: "linear-gradient(135deg, #1e1e1e, #0f0f0f)",
            animation: `${fadeIn} 1.5s ease-in-out`,
          }}
        >
          <Box
            sx={{
              maxWidth: "600px",
              textAlign: "center",
              marginRight: { sm: "2rem", xs: 0 },
              marginBottom: { sm: 0, xs: "2rem" },
            }}
          >
            <Typography
              variant="h3"
              fontWeight="bold"
              color="primary"
              gutterBottom
              sx={{
                fontSize: { xs: "2rem", sm: "3rem" },
                letterSpacing: "1px",
                textTransform: "uppercase",
              }}
            >
              Welcome to Limit Increase
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                marginBottom: "2rem",
                lineHeight: 1.8,
                fontSize: { xs: "1rem", sm: "1.25rem" },
                letterSpacing: "0.5px",
              }}
            >
              Empower your crypto journey with secure trading, investments, and next-gen DeFi tools.
            </Typography>
            <Box>
              <Button
                variant="contained"
                color="primary"
                size="large"
                component={Link}
                to="/login"
                sx={{ padding: "0.75rem 2rem", borderRadius: "8px", marginRight: "1rem", fontSize: "1.1rem" }}
              >
                Login
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                component={Link}
                to="/register"
                sx={{ padding: "0.75rem 2rem", borderRadius: "8px", fontSize: "1.1rem" }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
          <Avatar
            src="/images/hero1.png"
            sx={{
              width: { xs: 250, sm: 320 },
              height: { xs: 250, sm: 320 },
              borderRadius: "20%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
              marginTop: { xs: "2rem", sm: 0 },
            }}
          />
        </Box>

        <Container maxWidth="lg" sx={{ padding: "4rem 2rem" }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/p2p" style={{ textDecoration: "none", color: "inherit" }}>
                <Box
                  sx={{
                    background: "background.paper",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    padding: "2rem",
                    textAlign: "center",
                    "&:hover": { transform: "scale(1.05)", transition: "0.3s" },
                  }}
                >
                  <FaExchangeAlt size={40} color="#f7931a" />
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    P2P
                  </Typography>
                  <Typography variant="body2">
                    Seamlessly exchange your crypto assets with zero fees and maximum efficiency.
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/wallet" style={{ textDecoration: "none", color: "inherit" }}>
                <Box
                  sx={{
                    background: "background.paper",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    padding: "2rem",
                    textAlign: "center",
                    "&:hover": { transform: "scale(1.05)", transition: "0.3s" },
                  }}
                >
                  <FaWallet size={40} color="#1e90ff" />
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Wallet
                  </Typography>
                  <Typography variant="body2">
                    Safeguard and manage all your crypto assets in one secure place.
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/invest" style={{ textDecoration: "none", color: "inherit" }}>
                <Box
                  sx={{
                    background: "background.paper",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    padding: "2rem",
                    textAlign: "center",
                    "&:hover": { transform: "scale(1.05)", transition: "0.3s" },
                  }}
                >
                  <FaPiggyBank size={40} color="#f7931a" />
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Invest
                  </Typography>
                  <Typography variant="body2">
                    Unlock flexible investment options for high-yield returns.
                  </Typography>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Link to="/flash" style={{ textDecoration: "none", color: "inherit" }}>
                <Box
                  sx={{
                    background: "background.paper",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
                    padding: "2rem",
                    textAlign: "center",
                    "&:hover": { transform: "scale(1.05)", transition: "0.3s" },
                  }}
                >
                  <FaBolt size={40} color="#ff4500" />
                  <Typography variant="h6" fontWeight="bold" gutterBottom>
                    Flash Loans
                  </Typography>
                  <Typography variant="body2">
                    Quick, secure, and accessible flash loans for all your needs.
                  </Typography>
                </Box>
              </Link>
            </Grid>
          </Grid>
        </Container>

        {/* With You Every Step Section */}
        <Box sx={{ padding: "4rem 2rem", backgroundColor: "#111111", color: "white" }}>
          <Typography variant="h4" fontWeight="bold" gutterBottom>
            With you every step of the way
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: "700px",
              margin: "0 auto",
              marginBottom: "2rem",
            }}
          >
            From your first crypto trade to your latest financial milestone, Limit Increase is here to support your journey. Confidence starts here.
          </Typography>
          
        </Box>

        <Box sx={{ padding: "6rem 2rem", background: "linear-gradient(145deg, #1a1a1a, #333333)", color: "white" }}>
  {/* Header */}
  <Typography 
    variant="h2" 
    fontWeight="bold" 
    gutterBottom 
    sx={{ 
      textAlign: "center", 
      fontSize: "3.5rem", 
      letterSpacing: "1px", 
      lineHeight: 1.2, 
      textShadow: "0 4px 6px rgba(0, 0, 0, 0.3)",
      color: "white",
      marginBottom: "2rem"
    }}
  >
    One app. Unlimited possibilities.
  </Typography>

  {/* Main Content Grid */}
  <Grid container spacing={4} alignItems="center">
    {/* Left Side: Images */}
    <Grid item xs={12} sm={6} sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: "100%",
          transition: "transform 0.7s ease-in-out, opacity 0.5s ease-in-out",
          transform: selectedTab === "Exchange" ? "translateX(0)" : "translateX(-100%)",
          opacity: selectedTab === "Exchange" ? 1 : 0,
          boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
          borderRadius: "12px",
        }}
      >
        <img 
          src="/images/exchange_image.webp" 
          alt="Exchange" 
          style={{ width: "100%", borderRadius: "12px", objectFit: "cover" }} 
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          transition: "transform 0.7s ease-in-out, opacity 0.5s ease-in-out",
          transform: selectedTab === "Web3" ? "translateX(0)" : "translateX(100%)",
          opacity: selectedTab === "Web3" ? 1 : 0,
          boxShadow: "0 15px 25px rgba(0, 0, 0, 0.2)",
          borderRadius: "12px",
        }}
      >
        <img 
          src="/images/web3_image.webp" 
          alt="Web3" 
          style={{ width: "100%", borderRadius: "12px", objectFit: "cover" }} 
        />
      </Box>
    </Grid>

    {/* Right Side: Buttons & Description */}
    <Grid item xs={12} sm={6}>
      <Box sx={{ textAlign: "center" }}>
        {/* Buttons with Switch Effect */}
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color={selectedTab === "Exchange" ? "primary" : "secondary"}
              fullWidth
              onClick={() => handleTabClick("Exchange")}
              sx={{
                padding: "1.5rem",
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderRadius: "8px",
                textTransform: "uppercase",
                boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
                transition: "all 0.3s ease-in-out",
                background: selectedTab === "Exchange" ? "linear-gradient(145deg, #ff7f50, #ff6347)" : "transparent",
                color: selectedTab === "Exchange" ? "white" : "#ff6347",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              Exchange
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="contained"
              color={selectedTab === "Web3" ? "primary" : "secondary"}
              fullWidth
              onClick={() => handleTabClick("Web3")}
              sx={{
                padding: "1.5rem",
                fontWeight: "bold",
                fontSize: "1.1rem",
                borderRadius: "8px",
                textTransform: "uppercase",
                boxShadow: "0 8px 15px rgba(0, 0, 0, 0.3)",
                transition: "all 0.3s ease-in-out",
                background: selectedTab === "Web3" ? "linear-gradient(145deg, #8e44ad, #9b59b6)" : "transparent",
                color: selectedTab === "Web3" ? "white" : "#9b59b6",
                "&:hover": {
                  transform: "scale(1.05)",
                  boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
                },
              }}
            >
              Web3
            </Button>
          </Grid>
        </Grid>

        {/* Description */}
        <Box sx={{ marginTop: "2.5rem" }}>
          {selectedTab === "Exchange" ? (
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: "1.25rem", 
                maxWidth: "600px", 
                margin: "0 auto", 
                lineHeight: 1.8,
                color: "rgba(255, 255, 255, 0.85)", 
                fontFamily: "Roboto, sans-serif"
              }}
            >
              New to crypto? No problem. Buy crypto for as little as $5 in a tap, and grow your skills as you go.
            </Typography>
          ) : (
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: "1.25rem", 
                maxWidth: "600px", 
                margin: "0 auto", 
                lineHeight: 1.8,
                color: "rgba(255, 255, 255, 0.85)", 
                fontFamily: "Roboto, sans-serif"
              }}
            >
              Explore the world’s best NFT marketplace, DEX, and wallet that supports all of your favorite chains.
            </Typography>
          )}
        </Box>
      </Box>
    </Grid>
  </Grid>
</Box>

      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;
