import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const WithdrawForm = () => {
  const [formData, setFormData] = useState({
    walletAddress: '',
    walletType: 'USDT', // Default to USDT
    amount: '',
    key: '',
    confirmWithdrawal: false, // Flag to show confirmation input
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [userBalance, setUserBalance] = useState(0); // User's balance
  const [vipLevel, setVipLevel] = useState(0); // User's VIP level

  useEffect(() => {
    const fetchUserDetails = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await fetch('http://localhost:8000/api/user', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const result = await response.json();
        if (response.ok) {
          setUserBalance(result.balance); // Assume response contains user balance
          setVipLevel(result.vip_level); // Assume response contains VIP level
        } else {
          throw new Error(result.detail || 'Failed to fetch user details');
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    };

    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isAmountValid = (amount) => {
    const amountValue = parseFloat(amount);
    return amountValue > 0 && amountValue <= userBalance;
  };

  const getVipWithdrawalLimit = () => {
    const vipLimits = {
      0: 20, // Non-VIP
      1: 200,
      2: 900,
      3: 1500,
      4: 10000,
      5: 30000,
    };
    return vipLimits[vipLevel] || 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);

    if (formData.confirmWithdrawal) {
      // Check if withdrawal amount is valid
      if (!isAmountValid(formData.amount)) {
        setErrorMessage("Invalid amount. Ensure it's above zero and within your balance.");
        return;
      }

      // Check if the amount exceeds VIP withdrawal limit
      const vipLimit = getVipWithdrawalLimit();
      if (parseFloat(formData.amount) > vipLimit) {
        setErrorMessage(`Your VIP level allows a maximum withdrawal of ${vipLimit} USDT.`);
        return;
      }

      // Proceed with withdrawal
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        const response = await fetch('https://limitincrease.xyz/api/withdraw', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            wallet_address: formData.walletAddress,
            wallet_type: formData.walletType,
            amount: formData.amount,
            key: formData.key,
          }),
        });

        const result = await response.json();
        if (!response.ok) {
          const errorMsg =
            typeof result.detail === 'string' ? result.detail : 'Withdrawal failed. Please check your key.';
          throw new Error(errorMsg);
        }

        setSuccessMessage('Withdrawal successful!');
        setFormData({
          walletAddress: '',
          walletType: 'USDT',
          amount: '',
          key: '',
          confirmWithdrawal: false,
        });
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    } else {
      setFormData({ ...formData, confirmWithdrawal: true });
    }
  };

  const handleBackToForm = () => {
    setFormData({
      walletAddress: '',
      walletType: 'USDT',
      amount: '',
      key: '',
      confirmWithdrawal: false,
    });
    setErrorMessage(null);
    setSuccessMessage(null);
  };

  return (
    <WithdrawFormContainer>
      <h2>Withdraw Your Funds</h2>
      <Description>
        Securely withdraw funds from your account. Ensure the wallet address and type are accurate to avoid any errors. Confirm your withdrawal by entering your security key.
      </Description>
      <BalanceDisplay>
        <h3>Available Balance: {userBalance} USDT</h3>
        <VipNote>
          Your VIP Level: {vipLevel}. Maximum withdrawal limit: {getVipWithdrawalLimit()} USDT.
        </VipNote>
      </BalanceDisplay>
      <form onSubmit={handleSubmit}>
        {!formData.confirmWithdrawal ? (
          <>
            <FormField>
              <label>Wallet Address:</label>
              <Input
                type="text"
                name="walletAddress"
                value={formData.walletAddress}
                onChange={handleChange}
                placeholder="Enter your wallet address"
                required
              />
            </FormField>

            <FormField>
              <label>Wallet Type:</label>
              <Select
                name="walletType"
                value={formData.walletType}
                onChange={handleChange}
              >
                <option value="USDT">USDT</option>
                <option value="TRX">TRX</option>
                <option value="BTC">BTC</option>
                <option value="ETH">ETH</option>
                <option value="SOL">SOL</option>
                <option value="BNB">BNB</option>
                <option value="XRP">XRP</option>
                <option value="DOGE">DOGE</option>
                <option value="ADA">ADA</option>
                <option value="USDC">USDC</option>
              </Select>
            </FormField>

            <FormField>
              <label>Amount:</label>
              <Input
                type="number"
                name="amount"
                value={formData.amount}
                onChange={handleChange}
                placeholder="Enter the withdrawal amount"
                required
                min="0"
              />
            </FormField>

            <SubmitButton type="submit" disabled={loading}>
              {loading ? 'Processing...' : 'Withdraw'}
            </SubmitButton>
          </>
        ) : (
          <>
            <FormField>
              <label>Enter Security Key to Confirm:</label>
              <Input
                type="password"
                name="key"
                value={formData.key}
                onChange={handleChange}
                placeholder="Enter your key"
                required
              />
            </FormField>

            <SubmitButton type="submit" disabled={loading}>
              {loading ? 'Processing...' : 'Confirm Withdrawal'}
            </SubmitButton>
            <BackButton type="button" onClick={handleBackToForm}>
              &#8592; Back
            </BackButton>
          </>
        )}
      </form>

      {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </WithdrawFormContainer>
  );
};

export default WithdrawForm;

// Styled Components

const WithdrawFormContainer = styled.div`
  max-width: 540px;
  margin: 40px auto;
  padding: 40px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0px 8px 30px rgba(0, 0, 0, 0.15);
`;

const Description = styled.p`
  text-align: center;
  font-size: 16px;
  color: #7f8c8d;
  margin-bottom: 20px;
`;

const BalanceDisplay = styled.div`
  padding: 15px;
  background-color: #f7f7f7;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 20px;

  h3 {
    color: #333;
    margin-bottom: 5px;
  }
`;

const VipNote = styled.p`
  font-size: 14px;
  color: #3498db;
`;

const FormField = styled.div`
  margin-bottom: 20px;

  label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #444;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 14px;
  background: #28a745;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background: #218838;
  }
`;

const BackButton = styled.button`
  margin-top: 15px;
  padding: 12px;
  background: #f1f1f1;
  color: #3498db;
  border: none;
  width: 100%;
  border-radius: 8px;
`;

const SuccessMessage = styled.div`
  margin-top: 20px;
  padding: 12px;
  background: #d4edda;
  color: #155724;
  border-radius: 8px;
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  padding: 12px;
  background: #f8d7da;
  color: #721c24;
  border-radius: 8px;
`;
