import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import InvestmentForm from './InvestmentForm';
import StockPricesVertical from './StockPricesVertical';
import HomeNavigation from './HomeNavigation';
import SliderPage from './SliderPage';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

// Styled Components for layout
const InvestmentPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 40px 20px;
  background-color: #f4f7fb;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  margin: 20px auto;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  color: #0d47a1;
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Poppins', sans-serif;
`;

const Description = styled.p`
  font-size: 18px;
  color: #555;
  text-align: center;
  margin-bottom: 30px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
`;

const UserInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  align-items: center;
`;

const UserInfoHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const UserDetails = styled.div`
  font-size: 18px;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
  font-family: 'Poppins', sans-serif;
`;

const UserDetail = styled.p`
  margin: 5px 0;
  font-size: 16px;
  color: #555;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 350px;
  text-align: center;
`;

const ProgressLabel = styled.p`
  font-size: 16px;
  color: #333;
  margin: 0;
  font-family: 'Poppins', sans-serif;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 12px;
  background: #e6e6e6;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
`;

const ProgressFill = styled.div`
  height: 100%;
  width: ${({ $progress }) => `${$progress}%`};
  background: ${({ status }) =>
    status === 'MATURED' ? '#4caf50' : 
    status === 'ACTIVE' ? '#0d47a1' : 
    '#e0e0e0'};
  border-radius: 6px;
  transition: width 0.3s ease;
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  flex-grow: 1;
  margin-top: 30px;
`;

// Helper function to calculate progress percentage for active investments
const calculateProgress = (createdAt, maturedAt) => {
  const now = new Date();
  const start = new Date(createdAt);
  const end = new Date(maturedAt);
  
  const totalDuration = end - start;
  const elapsedDuration = now - start;
  
  if (elapsedDuration <= 0) return 0;
  if (elapsedDuration >= totalDuration) return 100;
  
  return (elapsedDuration / totalDuration) * 100;
};

// Function to safely calculate the expected return
const calculateExpectedReturn = (interestRate, investedAmount) => {
  const expectedReturn = (interestRate / 100) * investedAmount;
  return isNaN(expectedReturn) ? 0 : expectedReturn;
};

// Main Investment Page component
const Investment = () => {
  const [userData, setUserData] = useState({
    username: '',
    balance: 0,
    vipLevel: '',
    latestInvestment: '',
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token'); 
      if (!token) {
        setError('No token found, please log in');
        setLoading(false);
        return;
      }

      const response = await axios.get('https://limitincrease.xyz/api/current-investments', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log('Response data:', response.data);  // Debugging log

      const latestInvestment = response.data.latest_investment;

      if (!latestInvestment) {
        // If no latest investment
        setUserData({
          username: response.data.username,
          balance: response.data.balance,
          vipLevel: response.data.vip_level,
          latestInvestment: null,
        });
      } else {
        let progress = 0;

        if (latestInvestment.status === 'ACTIVE') {
          progress = calculateProgress(latestInvestment.created_at, latestInvestment.matured_at);
        } else if (latestInvestment.status === 'MATURED') {
          progress = 100;
        } else if (['PENDING', 'WITHDRAWN'].includes(latestInvestment.status)) {
          progress = 0;
        }

        setUserData({
          username: response.data.username,
          balance: response.data.balance,
          vipLevel: response.data.vip_level,
          latestInvestment: {
            ...latestInvestment,
            progress: progress,
          },
        });
      }
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Debugging log to check if state is updated
  console.log('User Data:', userData);

  if (loading) {
    return (
      <InvestmentPage>
        <Title>Loading...</Title>
        <Description>We are fetching your data, please wait a moment.</Description>
      </InvestmentPage>
    );
  }

  return (
    <InvestmentPage>
      <Title>Welcome to the Investment Forum</Title>
      <Description>
        Choose your investment package and complete your investment form below. We offer a range of high-yield packages to suit your financial goals.
      </Description>

      {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

      <UserInfoCard>
        <UserInfoHeader>
          <UserDetails>
            <UserDetail><strong>Username:</strong> {userData.username || 'N/A'}</UserDetail>
            <UserDetail><strong>Balance:</strong> {userData.balance ? userData.balance.toLocaleString() : 'N/A'} USDT</UserDetail>
            <UserDetail><strong>VIP Level:</strong> {userData.vipLevel || 'N/A'}</UserDetail>
          </UserDetails>
        </UserInfoHeader>

        {userData.latestInvestment ? (
          <ProgressContainer>
            <ProgressLabel><strong>Latest Investment Progress:</strong></ProgressLabel>
            <ProgressBar>
              <ProgressFill $progress={userData.latestInvestment.progress} status={userData.latestInvestment.status} />
            </ProgressBar>
            <ProgressLabel>Progress: {userData.latestInvestment.progress.toFixed(2)}%</ProgressLabel>
            
            <ProgressLabel>
              <strong>Expected Return:</strong> {userData.latestInvestment.expected_return} USDT
            </ProgressLabel>
          </ProgressContainer>
        ) : (
          <p>No investments found.</p>
        )}
      </UserInfoCard>

      <ContentSection>
      <InvestmentForm />
      <SliderPage/>
        <HomeNavigation/>
        <StockPricesVertical />
      </ContentSection>
    </InvestmentPage>
  );
};

export default Investment;
