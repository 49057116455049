import React, { useState } from "react";
import styled from "styled-components";

const P2PSell = () => {
  const [amount, setAmount] = useState("");
  const [coin, setCoin] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [paymentDetails, setPaymentDetails] = useState({
    email: "",
    accountNumber: "",
    swiftCode: "",
    bankName: "",
  });
  const [usdEquivalent, setUsdEquivalent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  // Handle form submission
  const handleSell = () => {
    setLoading(true);
    // Simulate API request to get USD equivalent
    setTimeout(() => {
      if (amount <= 0 || !coin || !paymentMethod || !paymentDetails.email) {
        setErrorMessage("Please fill in all fields with valid values.");
        setLoading(false);
        return;
      }
      setUsdEquivalent(amount * 21000); // Example: 1 BTC = 21,000 USD
      setLoading(false);
      setSuccessMessage("Transaction successful. Please check your Payoneer account.");
    }, 2000);
  };

  // Prevent negative values
  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value >= 0) {
      setAmount(value);
    }
  };

  // Handle dynamic payment details input based on payment method
  const handlePaymentDetailsChange = (e) => {
    const { name, value } = e.target;
    setPaymentDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container>
      <ContentBox>
        <Header>
          <h1>Sell Crypto P2P</h1>
          <h2>Enter the amount you want to sell and choose your preferred payment method.</h2>
          <small>Make sure your balance is sufficient before selling.</small>
        </Header>

        <InputSection>
          <InputLabel>Amount to Sell</InputLabel>
          <Input
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={handleAmountChange}
          />
          <InputLabel>Select Coin</InputLabel>
          <Select value={coin} onChange={(e) => setCoin(e.target.value)}>
            <option value="">Choose Coin</option>
            <option value="btc">Bitcoin (BTC)</option>
            <option value="eth">Ethereum (ETH)</option>
            <option value="usdt">Tether (USDT)</option>
          </Select>
        </InputSection>

        <PaymentMethod>
          <h3>Choose your Payment Method</h3>
          <p>Select how you want to receive your payment in USD</p>
          <Button onClick={() => setPaymentMethod("Payoneer")}>Payoneer</Button>
          <Button onClick={() => setPaymentMethod("Payeer")}>Payeer</Button>
          <Button onClick={() => setPaymentMethod("Wise")}>Wise</Button>
          <Button onClick={() => setPaymentMethod("AirTM")}>AirTM</Button>
          <Button onClick={() => setPaymentMethod("ABA Bank")}>ABA Bank</Button>
          <Button onClick={() => setPaymentMethod("Skrill")}>Skrill</Button>
          <Button onClick={() => setPaymentMethod("Bank of Georgia")}>Bank of Georgia</Button>
          <Button onClick={() => setPaymentMethod("KHQR")}>KHQR</Button>

          {paymentMethod && (
            <PaymentDetailsInput>
              <InputLabel>
                {paymentMethod === "Payoneer"
                  ? "Enter Payoneer Account Email"
                  : paymentMethod === "Payeer"
                  ? "Enter Payeer Account ID"
                  : paymentMethod === "Wise"
                  ? "Enter Wise Account Email"
                  : paymentMethod === "AirTM"
                  ? "Enter AirTM Account Email"
                  : paymentMethod === "ABA Bank"
                  ? "Enter Account Number, SWIFT Code, and Bank Name"
                  : paymentMethod === "Skrill"
                  ? "Enter Skrill Account Email"
                  : paymentMethod === "Bank of Georgia"
                  ? "Enter Account Number, SWIFT Code, and Bank Name"
                  : paymentMethod === "KHQR"
                  ? "Enter KHQR Details"
                  : ""}
              </InputLabel>
              {paymentMethod === "ABA Bank" || paymentMethod === "Bank of Georgia" ? (
                <>
                  <Input
                    type="text"
                    name="accountNumber"
                    placeholder="Account Number"
                    value={paymentDetails.accountNumber}
                    onChange={handlePaymentDetailsChange}
                  />
                  <Input
                    type="text"
                    name="swiftCode"
                    placeholder="SWIFT Code"
                    value={paymentDetails.swiftCode}
                    onChange={handlePaymentDetailsChange}
                  />
                  <Input
                    type="text"
                    name="bankName"
                    placeholder="Bank Name"
                    value={paymentDetails.bankName}
                    onChange={handlePaymentDetailsChange}
                  />
                </>
              ) : (
                <Input
                  type="text"
                  name="email"
                  placeholder={`Enter ${paymentMethod} Email`}
                  value={paymentDetails.email}
                  onChange={handlePaymentDetailsChange}
                />
              )}
            </PaymentDetailsInput>
          )}
        </PaymentMethod>

        <ConversionSummary>
          {usdEquivalent && (
            <>
              <SummaryItem>
                <strong>You are selling</strong> {amount} {coin}.
              </SummaryItem>
              <SummaryItem>
                <strong>USD Equivalent</strong> ${usdEquivalent}.
              </SummaryItem>
              <SummaryItem>
                <strong>Payment Method</strong> {paymentMethod || "Not selected yet"}.
              </SummaryItem>
              <SummaryItem>
                <strong>Account Details</strong>{" "}
                {paymentMethod === "ABA Bank" || paymentMethod === "Bank of Georgia"
                  ? `${paymentDetails.accountNumber}, ${paymentDetails.swiftCode}, ${paymentDetails.bankName}`
                  : paymentDetails.email || "Not provided yet"}.
              </SummaryItem>
            </>
          )}
        </ConversionSummary>

        {loading && <Spinner>Loading...</Spinner>}
        {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}

        <ActionButton onClick={handleSell}>Sell Now</ActionButton>
      </ContentBox>
    </Container>
  );
};

// Styled Components

const Container = styled.div`
  background: linear-gradient(135deg, #1d1d1d, #292929);
  display: flex;
  justify-content: center;
  padding: 40px 20px;
  min-height: 100vh;
`;

const ContentBox = styled.div`
  background-color: #181818;
  color: #fff;
  padding: 40px;
  max-width: 700px;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  margin-top: 30px;
`;

const Header = styled.header`
  text-align: center;
  h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 400;
    color: #ccc;
  }
  small {
    font-size: 1rem;
    color: #bbb;
  }
`;

const InputSection = styled.section`
  margin-top: 30px;
`;

const InputLabel = styled.label`
  display: block;
  font-size: 1.1rem;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  background-color: #2e2e2e;
  border: 1px solid #444;
  color: #fff;
  border-radius: 5px;
  font-size: 1.1rem;
  transition: border-color 0.3s;

  &:focus {
    border-color: #5c6bc0;
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  background-color: #2e2e2e;
  border: 1px solid #444;
  color: #fff;
  border-radius: 5px;
  font-size: 1.1rem;

  &:focus {
    border-color: #5c6bc0;
    outline: none;
  }
`;

const PaymentMethod = styled.section`
  margin-top: 40px;
  h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  p {
    font-size: 1.1rem;
    margin-bottom: 20px;
    color: #bbb;
  }
`;

const Button = styled.button`
  background-color: #3f51b5;
  color: #fff;
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #536dfe;
  }
`;

const PaymentDetailsInput = styled.div`
  margin-top: 15px;
`;

const ConversionSummary = styled.section`
  margin-top: 30px;
`;

const SummaryItem = styled.div`
  font-size: 1.1rem;
  margin-bottom: 15px;
`;

const ActionButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  padding: 15px 30px;
  font-size: 1.2rem;
  width: 100%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;

  &:hover {
    background-color: #66bb6a;
  }
`;

const Spinner = styled.div`
  margin-top: 30px;
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
`;

const SuccessMessage = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #4caf50;
  color: white;
  border-radius: 10px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  margin-top: 30px;
  padding: 20px;
  background-color: #f44336;
  color: white;
  border-radius: 10px;
  text-align: center;
`;

export default P2PSell;
