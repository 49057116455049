import React from 'react';
import styled from 'styled-components';
import { FaCheckCircle, FaBolt, FaCogs, FaDollarSign, FaNetworkWired, FaShieldAlt, FaPlug } from 'react-icons/fa';

// Shared Styles
const commonStyles = `
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin: 2rem 0;
  background-color: #f8f9fa;
`;

// Styled Components
const Section = styled.section`
  ${commonStyles}
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1.5rem;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  line-height: 1.7;
  color: #555;
  margin-bottom: 1rem;
  text-align: center;
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin: 1rem 0;
  width: 80%;
  max-width: 600px;
  background-color: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  transition: background-color 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    background-color: #007bff;
    transform: scale(1.05);
    
    /* Change color of text and strong elements inside on hover */
    span {
      color: #fff;
    }

    strong {
      color: #fff;
    }
  }
`;

const Icon = styled.span`
  font-size: 2rem;
  margin-right: 1.5rem;
  color: #007bff;
  transition: color 0.3s ease-in-out;

  ${FeatureItem}:hover & {
    color: #fff;
  }
`;

const FeatureText = styled.span`
  font-size: 1.1rem;
  font-weight: 500;
  color: #333;
  transition: color 0.3s ease-in-out;

  ${FeatureItem}:hover & {
    color: #fff !important; /* Ensure text color changes on hover */
  }

  strong {
    color: #007bff;
    transition: color 0.3s ease-in-out;

    ${FeatureItem}:hover & {
      color: #fff; /* Make strong text white on hover */
    }
  }
`;

const Button = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  font-size: 1.3rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }

  &:focus {
    outline: none;
  }
`;

// Utility Component for Reusable Features
const Feature = ({ icon, title, description }) => (
  <FeatureItem>
    <Icon>{icon}</Icon>
    <FeatureText><strong>{title}:</strong> {description}</FeatureText>
  </FeatureItem>
);

const USDTFlashSoftware = () => {
  return (
    <div>
      {/* Section 1 */}
      <Section>
        <Header> What Makes It Unique?</Header>
        <Paragraph>
          <strong>1:1 Realism:</strong> Every wallet reads it as real USDT, making it indistinguishable from actual Tether.
        </Paragraph>
        <Paragraph>
          <strong>Instant Confirmation:</strong> Transactions are validated on the spot for maximum efficiency.
        </Paragraph>
      </Section>

      {/* Section 2 */}
      <Section>
        <Header> Key Features</Header>
        <FeatureList>
          <Feature
            icon={<FaCheckCircle />}
            title="1:1 Realism"
            description="Every wallet reads it as real USDT, making it indistinguishable from actual Tether."
          />
          <Feature
            icon={<FaBolt />}
            title="Instant Confirmation"
            description="Transactions are validated on the spot for maximum efficiency."
          />
          <Feature
            icon={<FaCogs />}
            title="Always Up-to-Date"
            description="Frequent updates ensure peak performance and security."
          />
        </FeatureList>
      </Section>

      {/* Section 3 */}
      <Section>
        <Header> Method 1: The Perfect 1:1 USDT Clone</Header>
        <Paragraph>
          Imagine a flawless replica of USDT that looks and acts like the real deal. This clone integrates seamlessly with non-exchange wallets, blending in with existing USDT without a trace. Perfect for all private wallet transactions.
        </Paragraph>
      </Section>

      {/* Section 4 */}
      <Section>
        <Header> Method 2: The Flash Method</Header>
        <Paragraph>
          Send USDT amounts you don’t even own! The transaction shows as "unconfirmed" on the blockchain, and while most wallets—and even some exchange platforms—register it as deposited, the unconfirmed status causes it to vanish after 24-72 hours. Ideal for websites, online stores, casinos, and more.
        </Paragraph>
        <FeatureList>
          <Feature
            icon={<FaDollarSign />}
            title="Method 1"
            description="Best for personal wallets."
          />
          <Feature
            icon={<FaNetworkWired />}
            title="Method 2"
            description="Perfect for public platforms like websites and online services."
          />
        </FeatureList>
      </Section>

      {/* Section 5 */}
      <Section>
        <Header> Key Features</Header>
        <FeatureList>
          <Feature
            icon={<FaDollarSign />}
            title="1:1 USDT Transfers"
            description="Every transaction appears as real USDT in all wallets (1 USDT = $1)."
          />
          <Feature
            icon={<FaBolt />}
            title="Instant Confirmation"
            description="Lightning-fast transactions confirmed immediately—no waiting!"
          />
          <Feature
            icon={<FaNetworkWired />}
            title="Works on All Chains"
            description="Fully compatible with Ethereum, BNB, Polygon, Tron, and more!"
          />
          <Feature
            icon={<FaCogs />}
            title="Regular Updates"
            description="Our cutting-edge software evolves to stay ahead of the curve."
          />
        </FeatureList>
      </Section>

      {/* Section 6 */}
      <Section>
        <Header> Why Choose USDT Flash Software?</Header>
        <FeatureList>
          <Feature
            icon={<FaShieldAlt />}
            title="Universal Compatibility"
            description="Works with all major blockchain networks and wallets."
          />
          <Feature
            icon={<FaPlug />}
            title="Seamless Integration"
            description="No extra setup—just install, run, and send."
          />
          <Feature
            icon={<FaPlug />}
            title="Plug-and-Play Simplicity"
            description="Easy to use for everyone, from beginners to pros."
          />
        </FeatureList>
      </Section>

      {/* Section 7 */}
      <Section>
        <Header> Beginner-Friendly!</Header>
        <Paragraph>
          Every purchase includes a detailed guide to help you navigate and choose the best method for your needs. Never feel lost—we’re here to support you! We specialize in making unique and outstanding smart contracts. Everything runs from our servers—you just click and run!
        </Paragraph>
      </Section>

    </div>
  );
};

export default USDTFlashSoftware;
