import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header';
import CarouselComponent from './components/CarouselComponent';
import ServiceOverview from './components/ServiceOverview';
import StockPricesVertical from './components/StockPricesVertical';
import HomeNavigation from './components/HomeNavigation';
import FooterMenu from './components/FooterMenu';
import Wallet from './components/Wallet'; // Import the Wallet component
import Login from './components/LoginForm';  // Import the Login component
import Register from './components/RegisterForm';  // Import the Register component
import ResetPassword from './components/ResetPassword';  // Import the ResetPassword component
import Investment from './components/Investment'; // Import the Investment component
import './App.css';
import ProfilePage from './components/ProfilePage';
import RechargePage from './components/RechargePage'; 
import WithdrawForm from './components/WithdrawForm';
import TransferForm from './components/TransferForm';
import TransactionHistory from './components/TransactionHistory';
import HelpCenter from './components/HelpCenter';
import ContactSupport from './components/ContactSupport';
import VIP from './components/VIP';
import Dashboard from './components/Dashboard';
import Refferal from './components/ReferralRewards'
import AboutUs from './components/AboutUs';
import Benefits from './components/Benefits';
import Testimonials from './components/Testimonials';
import CTA from './components/CTA';
import PasswordResetRequest from './components/PasswordResetRequest';
import PasswordChangeForm from './components/PasswordChangeForm';
import Logout from './components/Logout';
import Terms from './components/Terms';
import Privacy from './components/Privacy';
import SliderPage from './components/SliderPage';
import Home from './components/Home';
import P2P from './components/P2P';
import P2PBuy from './components/P2PBuy';
import P2PSell from './components/P2PSell';
import Swap from './components/Swap';
import Flash from './components/Flash';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  // Authentication pages list
  const isAuthPage = location.pathname === '/login' || location.pathname === '/register' || location.pathname === '/reset-password' || location.pathname === '/request-password-reset';

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // If the user is on an auth page, don't perform any redirects
    if (isAuthPage) return;
  
    // If the token is missing or expired, clear the token and redirect to /home
    if (!token || isTokenExpired(token)) {
      localStorage.removeItem('token');
      navigate('/home');
      return;
    }
  
    // If the token is valid, ensure they are not redirected from non-auth pages unnecessarily
  }, [location, navigate, isAuthPage]);
  
  const isTokenExpired = (token) => {
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1])); // Decode JWT token
      const expiryTime = decodedToken.exp * 1000; // Token's expiry time (in ms)
      return expiryTime < Date.now(); // Check if token is expired
    } catch (e) {
      return true; // If decoding fails, treat token as expired
    }
  };

  return (
    <>
      {!isAuthPage && <Header />}

      <Routes>
        <Route path="/" element={
          <>
            <HomeNavigation />
            <ServiceOverview />
            <AboutUs />
            <Benefits />
            <Testimonials />
            <SliderPage />
            <StockPricesVertical />
            <CTA />
          </>
        } />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/investment" element={<Investment />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/recharge" element={<RechargePage />} />
        <Route path="/withdraw" element={<WithdrawForm />} />
        <Route path="/vip" element={<VIP />} />
        <Route path="/transfer" element={<TransferForm />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/transactions" element={<TransactionHistory />} />
        <Route path="/help" element={<HelpCenter />} />
        <Route path="/contact" element={<ContactSupport />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/referral" element={<Refferal />} />
        <Route path="/password-change" element={<PasswordChangeForm />} />
        <Route path="/request-password-reset" element={<PasswordResetRequest />} />
        <Route path="/market" element={<StockPricesVertical />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/home" element={<Home />} />
        <Route path="/p2p" element={<P2P />} />
        <Route path="/p2p-buy" element={<P2PBuy />} />
        <Route path="/p2p-sell" element={<P2PSell />} />
        <Route path="/swap" element={<Swap />} />
        <Route path="/flash" element={<Flash />} />
        {/* Other routes for additional pages */}
      </Routes>

      {!isAuthPage && <FooterMenu />}
    </>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
