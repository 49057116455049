import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";

const SwapContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #1a1a1a;
  color: white;
  font-family: "Inter", sans-serif;
  border-radius: 16px;
  width: 90%;
  max-width: 450px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const SwapTitle = styled.h2`
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  color: #ffd700;
`;

const CoinContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2c2c2c;
  padding: 15px;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 15px;
  position: relative;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.2);
`;

const CoinSelect = styled.select`
  background: #383838;
  border: none;
  color: white;
  font-size: 16px;
  flex: 1;
  margin-right: 10px;
  padding: 10px;
  border-radius: 8px;
  appearance: none;
  transition: all 0.3s ease;

  &:hover {
    background-color: #444;
  }
`;

const AmountInput = styled.input`
  background: transparent;
  border: none;
  color: white;
  font-size: 18px;
  text-align: right;
  flex: 1;
  outline: none;
  padding: 5px;
`;

const SwapIcon = styled.div`
  width: 60px;
  height: 60px;
  background-color: #ffd700;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffc107;
    transform: rotate(180deg);
  }
`;

const SwapIconText = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

const ConvertInfo = styled.div`
  text-align: center;
  font-size: 14px;
  margin-top: 10px;
  color: #b3b3b3;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 10px;
  width: 100%;

  &:hover {
    background-color: #0056b3;
  }

  &:active {
    transform: scale(0.98);
  }
`;

const CoinOption = styled.option`
  background-color: #383838;
  color: white;
`;

const SwapComponent = () => {
  const [coins, setCoins] = useState([]);
  const [fromCoin, setFromCoin] = useState("");
  const [toCoin, setToCoin] = useState("");
  const [amount, setAmount] = useState("");
  const [convertedAmount, setConvertedAmount] = useState("");
  const [coinIcons, setCoinIcons] = useState({});

  const supportedCoins = [
    { coin: "BTC", network: "Mainnet" },
    { coin: "ETH", network: "Ethereum Mainnet" },
    { coin: "ETH", network: "OP Network" },
    { coin: "ETH", network: "ARB1 Network" },
    { coin: "ETH", network: "BASE Network" },
    { coin: "TRX", network: "Mainnet" },
    { coin: "USDT", network: "TRX Network" },
    { coin: "USDT", network: "ETH Network" },
    { coin: "USDT", network: "SOL Network" },
    { coin: "USDT", network: "AVAXC Network" },
    { coin: "USDT", network: "POLYGON Network" },
    { coin: "USDT", network: "BSC Network" },
    { coin: "USDT", network: "ARB1 Network" },
    { coin: "USDT", network: "OP Network" },
    { coin: "PayPal USD", network: "SOL Network" },
    { coin: "BUSD", network: "ETH Network" },
    { coin: "BUSD", network: "BSC Network" },
    { coin: "PayPal USD", network: "ETH Network" },
  ];

  useEffect(() => {
    const fetchCoinIcons = async () => {
      const icons = {};
      for (const coin of supportedCoins) {
        try {
          const response = await axios.get(
            `https://api.coingecko.com/api/v3/coins/${coin.coin.toLowerCase()}`
          );
          icons[coin.coin] = response.data.image.thumb;
        } catch (error) {
          console.error(`Error fetching icon for ${coin.coin}:`, error);
        }
      }
      setCoinIcons(icons);
    };

    fetchCoinIcons();
    setCoins(supportedCoins);
  }, []);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setAmount(value);
      calculateConversion(value);
    } else {
      setAmount("");
      setConvertedAmount("");
    }
  };

  const handleSwap = () => {
    const temp = fromCoin;
    setFromCoin(toCoin);
    setToCoin(temp);
    calculateConversion(amount);
  };

  const calculateConversion = (amt) => {
    // Mock conversion rate for demonstration purposes
    const rate = 1.5; // Replace with actual API call to get rates
    if (amt && fromCoin && toCoin) {
      setConvertedAmount((amt * rate).toFixed(2));
    } else {
      setConvertedAmount("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(`Swapping ${amount} ${fromCoin} to ${toCoin}`);
    // Add actual swap logic here
  };

  return (
    <SwapContainer>
      <SwapTitle>Crypto Converter</SwapTitle>
      <CoinContainer>
        <CoinSelect
          value={fromCoin}
          onChange={(e) => setFromCoin(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a coin
          </option>
          {coins.map((coin, index) => (
            <CoinOption
              key={index}
              value={`${coin.coin} (${coin.network})`}
            >
              {coin.coin} ({coin.network})
            </CoinOption>
          ))}
        </CoinSelect>
        <AmountInput
          type="number"
          value={amount}
          onChange={handleAmountChange}
          placeholder="0.00"
          required
        />
      </CoinContainer>
      <SwapIcon onClick={handleSwap}>
        <SwapIconText>⇅</SwapIconText>
      </SwapIcon>
      <CoinContainer>
        <CoinSelect
          value={toCoin}
          onChange={(e) => setToCoin(e.target.value)}
          required
        >
          <option value="" disabled>
            Select a coin
          </option>
          {coins.map((coin, index) => (
            <CoinOption
              key={index}
              value={`${coin.coin} (${coin.network})`}
            >
              {coin.coin} ({coin.network})
            </CoinOption>
          ))}
        </CoinSelect>
        <AmountInput
          type="text"
          value={convertedAmount}
          placeholder="0.00"
          readOnly
        />
      </CoinContainer>
      <ConvertInfo>
        {amount && fromCoin && toCoin
          ? `${amount} ${fromCoin} ≈ ${convertedAmount} ${toCoin}`
          : "Enter an amount to see the conversion"}
      </ConvertInfo>
      <SubmitButton onClick={handleSubmit}>Convert</SubmitButton>
    </SwapContainer>
  );
};

export default SwapComponent;
