import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0047ba, #002f84);
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Heading = styled.h2`
  color: #0047ba;
  font-size: 2.4em;
  margin-bottom: 16px;
  font-weight: 700;
`;

const SubText = styled.p`
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  margin-top: 20px;
`;

const RightSide = styled.div`
  background-color: #ffffff;
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 1.1em;
  color: #555;
  margin-bottom: 8px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  color: #333;
  background-color: #f7f9fc;
  transition: all 0.3s ease;

  &:focus {
    border-color: #0047ba;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 71, 186, 0.5);
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px 15px;
  background-color: #0047ba;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #002f84;
  }

  &:active {
    background-color: #001c4d;
  }
`;

const Message = styled.p`
  text-align: center;
  color: ${(props) => (props.success ? '#27ae60' : '#e74c3c')};
  font-size: 1.1em;
  margin-top: 20px;
`;

const ExtraLinks = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 1.1em;
  color: #555;
`;

const StyledLink = styled(Link)`
  color: #0047ba;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const LoginForm = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const requestBody = {
      email: formData.email,
      password: formData.password,
    };
  
    try {
      const response = await fetch('https://limitincrease.xyz/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('Login successful:', data);
  
        // Store the access token in localStorage
        localStorage.setItem('token', data.access_token);
  
        // Display success message
        setMessage('Login successful!');
  
        // Redirect to home page after successful login
        setTimeout(() => navigate('/'), 1000); // Delay for user to see the success message
      } else {
        const errorData = await response.json();
  
        // Display the error message from the backend response
        setMessage(errorData.message || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error:', error);
  
      // Handle unexpected errors
      setMessage('An unexpected error occurred. Please try again later.');
    }
  };
  

  return (
    <Container>
      <ContentWrapper>
        {/* Left Side */}
        <LeftSide>
          <Heading>Trade with confidence</Heading>
          <SubText>
            Your funds are always backed 1:1 on limitincrease with our regularly published audits on our Proof of Reserves.
          </SubText>
          <Image src="/images/trade.webp" alt="Proof of Reserves" />
        </LeftSide>

        {/* Right Side */}
        <RightSide>
          <Heading>Login</Heading>
          <form onSubmit={handleSubmit}>
            <div>
              <Label>Email:</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Label>Password:</Label>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <SubmitButton type="submit">Login</SubmitButton>
          </form>

          {message && (
            <Message success={message === 'Login successful!'}>
              {message}
            </Message>
          )}


          <ExtraLinks>
            <p>
              Forgot your password? <StyledLink to="/request-password-reset">Reset here</StyledLink>
            </p>
            <p>
              Don't have an account? <StyledLink to="/register">Register here</StyledLink>
            </p>
          </ExtraLinks>
        </RightSide>
      </ContentWrapper>
    </Container>
  );
};

export default LoginForm;
