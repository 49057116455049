import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';

// Styled Components
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: linear-gradient(to bottom, #0047ba, #002f84);
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1200px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const LeftSide = styled.div`
  background-color: #ffffff;
  padding: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Heading = styled.h2`
  color: #0047ba;
  font-size: 2.4em;
  margin-bottom: 16px;
  font-weight: 700;
`;

const SubText = styled.p`
  color: #333;
  font-size: 1.1em;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 250px;
  border-radius: 10px;
  margin-top: 20px;
`;

const RightSide = styled.div`
  background-color: #ffffff;
  padding: 40px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const Label = styled.label`
  display: block;
  font-size: 1.1em;
  color: #555;
  margin-bottom: 8px;
  font-weight: 600;
`;

const Input = styled.input`
  width: 100%;
  padding: 12px 15px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 1em;
  color: #333;
  background-color: #f7f9fc;
  transition: all 0.3s ease;

  &:focus {
    border-color: #0047ba;
    background-color: #fff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 71, 186, 0.5);
  }
`;

const PhoneInputWrapper = styled.div`
  margin-bottom: 20px;

  .react-tel-input {
    width: 100%;
    .form-control {
      width: 100%;
      padding: 12px 15px;
      border-radius: 8px;
      border: 1px solid #ddd;
      font-size: 1em;
      color: #333;
      background-color: #f7f9fc;
      transition: all 0.3s ease;

      &:focus {
        border-color: #0047ba;
        background-color: #fff;
        outline: none;
        box-shadow: 0 0 8px rgba(0, 71, 186, 0.5);
      }
    }
  }
`;

const SubmitButton = styled.button`
  width: 100%;
  padding: 12px 15px;
  background-color: #0047ba;
  color: white;
  font-size: 1.2em;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #002f84;
  }

  &:active {
    background-color: #001c4d;
  }
`;

const Message = styled.p`
  text-align: center;
  color: ${(props) => (props.success ? '#27ae60' : '#e74c3c')};
  font-size: 1.1em;
  margin-top: 20px;
`;

const LoginLink = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 1.1em;
  color: #555;
`;

const StyledLink = styled(Link)`
  color: #0047ba;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
`;

const LoadingSpinner = styled.div`
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #0047ba;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const KeySection = styled.div`
  margin-top: 20px;
  text-align: center;

  p {
    margin: 5px 0;
    font-size: 1em;
  }

  strong {
    color: #0047ba;
  }

  .warning {
    color: red;
    font-weight: bold;
    font-size: 0.95em;
  }
`;

const RegisterForm = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
    referred_by: '',
  });

  const [message, setMessage] = useState('');
  const [key, setKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    };

    const referred_by = getCookie('referred_by');
    if (referred_by) {
      setFormData((prevData) => ({ ...prevData, referred_by: referred_by }));
    }
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const requestBody = {
      username: formData.username,
      email: formData.email,
      phone: formData.phone,
      password: formData.password,
      confirm_password: formData.confirmPassword,
      referred_by: formData.referred_by,
    };

    try {
      const response = await fetch('https://limitincrease.xyz/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      setLoading(false);

      if (response.ok) {
        const data = await response.json();
        setMessage(data.message);
        setKey(data.key);
        setOpen(true);
      } else {
        const errorData = await response.json();
        setMessage(errorData.detail);
      }
    } catch (error) {
      setLoading(false);
      setMessage('An error occurred. Please try again.');
    }
  };

  return (
    <Container>
      <ContentWrapper>
        {/* Left Side */}
        <LeftSide>
          <Heading>Trade with confidence</Heading>
          <SubText>
            Your funds are always backed 1:1 on limitincrease with our regularly published audits on our Proof of Reserves.
          </SubText>
          <Image src="/images/trade.webp" alt="Proof of Reserves" />
        </LeftSide>

        {/* Right Side */}
        <RightSide>
          <Heading>Register</Heading>
          <form onSubmit={handleSubmit}>
            <div>
              <Label>Username:</Label>
              <Input
                type="text"
                name="username"
                value={formData.username}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Label>Email:</Label>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Label>Phone:</Label>
              <PhoneInputWrapper>
                <PhoneInput
                  country={'us'}
                  value={formData.phone}
                  onChange={handlePhoneChange}
                />
              </PhoneInputWrapper>
            </div>
            <div>
              <Label>Password:</Label>
              <Input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <Label>Confirm Password:</Label>
              <Input
                type="password"
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>

            <SubmitButton type="submit">
              {loading ? <LoadingSpinner /> : 'Create Account'}
            </SubmitButton>
          </form>

          <Dialog 
            open={open} 
            onClose={() => setOpen(false)} 
            maxWidth="sm" 
            fullWidth
            PaperProps={{
              style: {
                backgroundColor: '#1a1a2e', // Dark background
                color: '#fff', // Light text
                borderRadius: '12px', // Rounded corners
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.5)', // Subtle shadow for depth
              },
            }}
          >
            <DialogTitle 
              style={{
                background: 'linear-gradient(90deg, #6a00ff, #4deeea)', // Crypto gradient
                WebkitBackgroundClip: 'text', 
                WebkitTextFillColor: 'transparent',
                fontWeight: 'bold',
                fontSize: '1.5rem',
                textAlign: 'center',
              }}
            >
              Important: Your Key
            </DialogTitle>
            <DialogContent style={{ padding: '24px', textAlign: 'center' }}>
              <p style={{
                fontSize: '1.2rem', 
                margin: '16px 0', 
                background: 'linear-gradient(90deg, #4deeea, #6a00ff)', 
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}>
                <strong>Your Key:</strong> {key}
              </p>
              <p 
                className="warning" 
                style={{
                  color: '#ff5e5e', 
                  fontSize: '1rem', 
                  margin: '12px 0',
                }}
              >
                Please store this key securely. It will not be displayed again.
              </p>
              {message && (
                <p 
                  style={{
                    color: '#8cff8c', 
                    fontSize: '1rem', 
                    marginTop: '20px',
                    fontStyle: 'italic',
                  }}
                >
                  {message}
                </p>
              )}
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center', padding: '16px' }}>
              <Button 
                onClick={() => setOpen(false)} 
                style={{
                  backgroundColor: '#4deeea',
                  color: '#000', 
                  fontWeight: 'bold',
                  padding: '10px 20px',
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontSize: '1rem',
                }}
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>




          <LoginLink>
            Already have an account? <StyledLink to="/login">Log in</StyledLink>
          </LoginLink>
        </RightSide>
      </ContentWrapper>
    </Container>
  );
};

export default RegisterForm;
