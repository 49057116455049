import React, { useState } from "react";
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box, Button } from "@mui/material";
import { Menu as MenuIcon, AccountCircle } from "@mui/icons-material";
import { Link } from "react-router-dom";

const Header = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <AppBar position="sticky" sx={{ background: "linear-gradient(135deg, #0f0f0f, #1e1e1e)", boxShadow: 3 }}>
      <Toolbar sx={{ justifyContent: "space-between", alignItems: "center" }}>
        {/* Logo */}
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ color: "white", textDecoration: "none", fontWeight: "bold", letterSpacing: "1px" }}
        >
          Limit Increase
        </Typography>

        {/* Desktop Navigation */}
        <Box sx={{ display: { xs: "none", md: "flex" }, gap: 2 }}>

          <Button component={Link} to="/wallet" color="inherit">
            Wallet
          </Button>
          <Button component={Link} to="/investment" color="inherit">
            Investment
          </Button>
          <Button component={Link} to="/flash" color="inherit">
          Flash-USDT
          </Button>
          <Button component={Link} to="/p2p" color="inherit">
            P2P
          </Button>
       
        </Box>

        {/* Profile Menu */}
        <IconButton
          size="large"
          edge="end"
          color="inherit"
          aria-label="account settings"
          onClick={handleProfileMenuOpen}
        >
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ mt: "45px" }}
        >
          <MenuItem component={Link} to="/help" onClick={handleMenuClose}>
            Help Center
          </MenuItem>
          <MenuItem component={Link} to="/password-change" onClick={handleMenuClose}>
            Password Change
          </MenuItem>
          
          <MenuItem component={Link} to="/logout" onClick={handleMenuClose}>
            Logout
          </MenuItem>
        </Menu>

        {/* Mobile Menu */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="mobile menu"
          sx={{ display: { xs: "flex", md: "none" } }}
          onClick={toggleMobileMenu}
        >
          <MenuIcon />
        </IconButton>
        {mobileMenuOpen && (
          <Box
            sx={{
              position: "absolute",
              top: "64px",
              right: 0,
              background: "linear-gradient(135deg, #1e1e1e, #0f0f0f)",
              width: "200px",
              zIndex: 1200,
              boxShadow: 3,
              borderRadius: 1,
            }}
          >
            <MenuItem component={Link} to="/" onClick={toggleMobileMenu}>
              Home
            </MenuItem>
            <MenuItem component={Link} to="/wallet" onClick={toggleMobileMenu}>
              Wallet
            </MenuItem>
            <MenuItem component={Link} to="/investment" onClick={toggleMobileMenu}>
              Investment
            </MenuItem>
            <MenuItem component={Link} to="/flash" onClick={toggleMobileMenu}>
            Flash-USDT
            </MenuItem>
            <MenuItem component={Link} to="/p2p" onClick={toggleMobileMenu}>
            P2P
            </MenuItem>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
