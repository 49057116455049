import React, { useState } from "react";
import styled from "styled-components";
import { FaRegCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate

// Styled Components
const ListContainer = styled.div`
  padding: 60px 20px;
  background: linear-gradient(180deg, #0d1117, #161b22);
  color: #e6edf3;
  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 40px 10px;
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 30px;
  }
`;

const Title = styled.h1`
  color: #00c6ff;
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

const Subtitle = styled.p`
  font-size: 1.3em;
  color: #8b949e;

  @media (max-width: 768px) {
    font-size: 1.1em;
  }
`;

const TabContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  flex-wrap: wrap;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  margin: 0 10px;
  background: ${(props) => (props.active ? "#00c6ff" : "#1e252e")};
  color: ${(props) => (props.active ? "#fff" : "#8b949e")};
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: ${(props) => (props.active ? "#00aaff" : "#272d37")};
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
    padding: 8px 15px;
    margin: 5px;
  }
`;

const TableSection = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  background: #1e252e;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 15px;

  th,
  td {
    padding: 15px;
    text-align: left;
    font-size: 1em;
    min-width: 120px; /* Ensure columns have a minimum width */
  }

  th {
    color: #8b949e;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 0.9em;
    border-bottom: 2px solid #2f3740;
  }

  tr {
    background: #272d37;
    transition: all 0.3s ease;

    &:hover {
      transform: scale(1.02);
      background: #313944;
    }
  }

  td {
    color: #e6edf3;
    border-bottom: 1px solid #2f3740;

    &:last-child {
      text-align: center;
    }
  }

  /* Responsive styles for smaller screens */
  @media (max-width: 768px) {
    display: block; /* Converts table to block layout */
    th {
      display: none; /* Hide table headers */
    }

    tr {
      display: block; /* Each row becomes a block */
      margin-bottom: 15px;
    }

    td {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid #2f3740;
    }

    td::before {
      content: attr(data-label); /* Use data-label to simulate headers */
      font-weight: bold;
      text-transform: uppercase;
      color: #8b949e;
    }
  }
`;


const MobileCardContainer = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
    padding: 0 10px;
  }
`;

const MobileCard = styled.div`
  background: #272d37;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
`;

const CardRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    color: ${(props) => (props.label ? "#8b949e" : "#e6edf3")};
    font-weight: ${(props) => (props.label ? "400" : "600")};
    font-size: ${(props) => (props.label ? "0.9em" : "1em")};
  }
`;

const ActionButton = styled.button`
  padding: 10px 20px;
  background: #00c6ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  transition: all 0.3s ease;

  &:hover {
    background: #008ccf;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9em;
  }
`;

const PaginationControls = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PaginationButton = styled.button`
  padding: 10px 20px;
  background: #00c6ff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px;

  &:disabled {
    background: #8b949e;
    cursor: not-allowed;
  }

  &:hover {
    background: #008ccf;
  }

  @media (max-width: 768px) {
    padding: 8px 15px;
    font-size: 0.9em;
  }
`;

const P2PBuy = ({ offers, onPageChange, currentPage, onSelectSeller }) => {
  const itemsPerPage = 5;
  const startIndex = currentPage * itemsPerPage;
  const currentOffers = offers.slice(startIndex, startIndex + itemsPerPage);

  return (
    <TableSection>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>Seller</th>
              <th>Transactions</th>
              <th>Completion</th>
              <th>Unit Price</th>
              <th>Available</th>
              <th>Order Limit</th>
              <th>Payment Methods</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentOffers.map((offer, index) => (
              <tr key={index}>
                <td>{offer.seller}</td>
                <td>{offer.transactions}</td>
                <td>{offer.completion}</td>
                <td>{offer.price}</td>
                <td>{offer.available}</td>
                <td>{offer.limit}</td>
                <td>{offer.methods.join(", ")}</td>
                <td>
                  <ActionButton onClick={() => onSelectSeller(offer)}>Buy</ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
      <PaginationControls>
        <PaginationButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 0}>
          Prev
        </PaginationButton>
        <PaginationButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= Math.ceil(offers.length / itemsPerPage) - 1}>
          Next
        </PaginationButton>
      </PaginationControls>
    </TableSection>
  );
};

const P2PSell = ({ offers, onPageChange, currentPage, onSelectSeller }) => {
  const itemsPerPage = 5;
  const startIndex = currentPage * itemsPerPage;
  const currentOffers = offers.slice(startIndex, startIndex + itemsPerPage);

  return (
    <TableSection>
      <TableWrapper>
        <StyledTable>
          <thead>
            <tr>
              <th>Seller</th>
              <th>Transactions</th>
              <th>Completion</th>
              <th>Unit Price</th>
              <th>Available</th>
              <th>Order Limit</th>
              <th>Payment Methods</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentOffers.map((offer, index) => (
              <tr key={index}>
                <td>{offer.seller}</td>
                <td>{offer.transactions}</td>
                <td>{offer.completion}</td>
                <td>{offer.price}</td>
                <td>{offer.available}</td>
                <td>{offer.limit}</td>
                <td>{offer.methods.join(", ")}</td>
                <td>
                  <ActionButton onClick={() => onSelectSeller(offer)}>Sell</ActionButton>
                </td>
              </tr>
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
      <PaginationControls>
        <PaginationButton onClick={() => onPageChange(currentPage - 1)} disabled={currentPage <= 0}>
          Prev
        </PaginationButton>
        <PaginationButton onClick={() => onPageChange(currentPage + 1)} disabled={currentPage >= Math.ceil(offers.length / itemsPerPage) - 1}>
          Next
        </PaginationButton>
      </PaginationControls>
    </TableSection>
  );
};

const P2PList = () => {
  const [activeTab, setActiveTab] = useState("Buy");
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedSeller, setSelectedSeller] = useState(null); // New state to capture selected seller
  const navigate = useNavigate();

  const generateDummyData = () => {
    const sellers = [
      "AWExchange", "CryptoBay", "CoinMarket", "USDTDeals", "CryptoShop", "SafeTrade", 
      "SecureTrade", "P2PPro", "PayFast", "CoinStore", "ExchangeHub", "TradeX", 
      "P2PTrade", "CryptoMax", "TradeNow", "QuickBuy", "CoinXChange", "SwapFast"
    ];

    const paymentMethods = [
      "Payoneer", "Payeer", "Wise", "AirTM", "ABA Bank", "Skrill", "Bank of Georgia", "KHQR"
    ];

    let data = [];

    for (let i = 0; i < 34; i++) {
      const seller = sellers[i % sellers.length];
      const available = (Math.random() * 5000).toFixed(2);
      const price = (Math.random() * 2 + 1).toFixed(3);
      const limit = `${(Math.random() * 100).toFixed(2)} - ${(Math.random() * 5000).toFixed(2)} USDT`;
      const methods = [paymentMethods[i % paymentMethods.length]];

      const isBuy = Math.random() > 0.5;
      data.push({
        seller,
        transactions: `${(Math.random() * 10000).toFixed(0)}`,
        completion: `${(Math.random() * 100).toFixed(1)}%`,
        price: `${price} USD`,
        available: `${available} USDT`,
        limit,
        methods,
        type: isBuy ? "Buy" : "Sell",
      });
    }

    return data;
  };

  const dummyData = generateDummyData();
  const buyOffers = dummyData.filter((offer) => offer.type === "Buy");
  const sellOffers = dummyData.filter((offer) => offer.type === "Sell");

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSelectSeller = (seller) => {
    setSelectedSeller(seller);
    // Navigate to the corresponding page based on the active tab
    if (activeTab === "Buy") {
      navigate("/p2p-buy", { state: { selectedSeller } });
    } else {
      navigate("/p2p-sell", { state: { selectedSeller } });
    }
  };

  return (
    <ListContainer>
      <Header>
        <Title>Trade P2P with Zero Fees</Title>
        <Subtitle>Buy USDT with USD at your preferred rates</Subtitle>
      </Header>
      <TabContainer>
        <TabButton active={activeTab === "Buy"} onClick={() => setActiveTab("Buy")}>
          Buy
        </TabButton>
        <TabButton active={activeTab === "Sell"} onClick={() => setActiveTab("Sell")}>
          Sell
        </TabButton>
      </TabContainer>
      {activeTab === "Buy" ? (
        <P2PBuy offers={buyOffers} currentPage={currentPage} onPageChange={handlePageChange} onSelectSeller={handleSelectSeller} />
      ) : (
        <P2PSell offers={sellOffers} currentPage={currentPage} onPageChange={handlePageChange} onSelectSeller={handleSelectSeller} />
      )}
    </ListContainer>
  );
};

export default P2PList;
