import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const ContentContainer = styled.div`
  padding: 50px 30px;
  background: linear-gradient(180deg, #0d1117, #161b22);
  color: #e6edf3;
  min-height: 100vh;
`;

const Section = styled.div`
  margin-top: 40px;
  padding: 40px;
  background: #1e252e;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3);
`;

const SectionHeader = styled.h2`
  color: #00c6ff;
  text-align: center;
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 30px;
`;

const SectionGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  text-align: center;
`;

const SectionItem = styled.div`
  padding: 20px;
  background: #272d37;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  text-align: center;

  img {
    height: 60px;
    margin-bottom: 15px;
  }

  p {
    font-size: 1.1em;
    color: #8b949e;
  }
`;

const PaymentMethods = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;

  span {
    background: #00c6ff;
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1em;
    font-weight: 500;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    transition: background 0.3s ease;

    &:hover {
      background: #00aaff;
    }
  }
`;

const FAQAccordion = styled.div`
  max-width: 900px;
  margin: 0 auto;
`;

const FAQItem = styled.div`
  background: #272d37;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
`;

const FAQQuestion = styled.div`
  padding: 20px;
  font-size: 1.2em;
  font-weight: 600;
  color: #00c6ff;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background: #313944;
  }
`;

const FAQAnswer = styled.div`
  padding: 20px;
  font-size: 1em;
  color: #e6edf3;
  display: ${(props) => (props.open ? "block" : "none")};
  background: #1e252e;
  border-top: 1px solid #2f3740;
`;

const P2PContent = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const handleFAQToggle = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  const faqData = [
    { question: "What is P2P trading?", answer: "P2P trading allows users to trade crypto directly with each other without intermediaries." },
    { question: "Am I buying/selling from Limit Increase directly?", answer: "No, you are trading with other users on the platform." },
    { question: "How do I become a P2P Merchant?", answer: "To become a merchant, apply via the Limit Increase platform and meet the necessary requirements." },
    { question: "Which crypto can I buy via Limit Increase P2P trading?", answer: "You can buy USDT, BTC, ETH, and other supported cryptocurrencies." },
    { question: "How can I protect my assets from P2P scammers?", answer: "Always verify payment and follow platform guidelines for secure transactions." },
  ];

  return (
    <ContentContainer>
      {/* Why Limit Increase
       P2P Section */}
      <Section>
        <SectionHeader>Why Limit Increase
           P2P?</SectionHeader>
        <SectionGrid>
          <SectionItem>
            <img
              src="https://www.okx.com/cdn/assets/imgs/232/CE8F51DB49638F59.svg"
              alt="Zero Fees"
            />
            <p>P2P trading on Limit Increase
               is free — don't miss the opportunity</p>
          </SectionItem>
          <SectionItem>
            <img
              src="https://www.okx.com/cdn/assets/imgs/232/69459D4337A9D464.svg"
              alt="Build Community"
            />
            <p>Grow your network as a Super Merchant</p>
          </SectionItem>
          <SectionItem>
            <img
              src="https://www.okx.com/cdn/assets/imgs/232/F856F2FF4EB126DB.svg"
              alt="Unparalleled Choices"
            />
            <p>900+ local payment methods and 100+ currencies to choose from</p>
          </SectionItem>
        </SectionGrid>
      </Section>

      {/* Payment Methods Section */}
      <Section>
        <SectionHeader>Flexible Payment Methods to Suit Every Need</SectionHeader>
        <PaymentMethods>
          {[
            "Payoneer",
            "Payeer",
            "Wise",
            "AirTM",
            "ABA Bank",
            "Skrill",
            "Bank of Georgia",
            "KHQR",
          ].map((method, index) => (
            <span key={index}>{method}</span>
          ))}
        </PaymentMethods>
      </Section>

      {/* FAQ Section */}
      <Section>
        <SectionHeader>FAQ</SectionHeader>
        <FAQAccordion>
          {faqData.map((item, index) => (
            <FAQItem key={index}>
              <FAQQuestion onClick={() => handleFAQToggle(index)}>
                {item.question}
                <span>{openFAQ === index ? "-" : "+"}</span>
              </FAQQuestion>
              <FAQAnswer open={openFAQ === index}>{item.answer}</FAQAnswer>
            </FAQItem>
          ))}
        </FAQAccordion>
      </Section>
    </ContentContainer>
  );
};

export default P2PContent;
