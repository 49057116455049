import React, { useState } from "react";
import styled from "styled-components";
import { FaSyncAlt } from "react-icons/fa";  // Importing the refresh icon

// Styled Components
const Body = styled.div`
  background-color: #131920;
  min-height: 100vh;
  padding: 20px;
`;

const Container = styled.div`
  margin: 30px auto;
  padding: 20px;
  max-width: 600px;
  background: #1a1e25;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  color: #fff;
  font-family: Arial, sans-serif;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Title = styled.h2`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1.8em;
  color: #00c6ff;
`;

const InstructionText = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 1em;
  color: #8b949e;
`;

const FormGroup = styled.div`
  margin-bottom: 20px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 8px;
  font-size: 1em;
  color: #8b949e;
`;

const Input = styled.input`
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #444c56;
  background: #2c333f;
  color: #fff;
  font-size: 1em;
  box-sizing: border-box;

  &:focus {
    border-color: #00c6ff;
    outline: none;
  }
`;

const Select = styled.select`
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  border: 2px solid #444c56;
  background: #2c333f;
  color: #fff;
  font-size: 1em;
  box-sizing: border-box;

  &:focus {
    border-color: #00c6ff;
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 10px;
  background: ${(props) => (props.secondary ? "#6c757d" : "#00c6ff")};
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1.1em;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.secondary ? "#5a6268" : "#0099cc")};
  }
`;

const DepositDetails = styled.div`
  margin-top: 20px;
  padding: 20px;
  background: #272d37;
  border-radius: 10px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
`;

const DetailsRow = styled.div`
  margin-bottom: 15px;
`;

const DetailsLabel = styled.span`
  display: block;
  color: #8b949e;
  margin-bottom: 5px;
  font-size: 0.9em;
`;

const DetailsValue = styled.span`
  font-size: 1em;
  font-weight: 600;
  color: #fff;
`;

const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-size: 18px;
`;

const StatusText = styled.p`
  font-size: 18px;
  color: #aaa;
  margin-right: 10px;  // Add some spacing between text and icon
`;

const RefreshIcon = styled(FaSyncAlt)`
  font-size: 20px;
  color: #00b0ff;
  cursor: pointer;
  transition: all 0.3s;
  
  &:hover {
    color: #008ec1;
  }
`;

const Instructions = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background: #2c333f;
  border-radius: 10px;
  color: #8b949e;
  font-size: 0.9em;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
`;

const ErrorMessage = styled.p`
  color: #ff4d4d;
  font-size: 0.9em;
  margin-top: 5px;
  margin-bottom: 15px;
`;

const P2PBuy = () => {
  const [amount, setAmount] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [showDepositDetails, setShowDepositDetails] = useState(false);
  const [error, setError] = useState("");
  const [transactionStatus, setTransactionStatus] = useState("Pending");
  const [transactionReference, setTransactionReference] = useState("REF123456789");

  const paymentMethods = [
    "Bank Transfer",
    "PayPal",
    "Crypto Transfer",
    "Payoneer",
    "Payeer",
    "Wise",
    "AirTM",
    "ABA Bank",
    "Skrill",
    "Bank of Georgia",
    "KHQR",
  ];

  const depositDetailsMap = {
    "Bank Transfer": "Bank: Chase Bank: 2001346676541\nSWIFT: CHASUS33",
    "PayPal": "PayPal Email: dwaynesigner@gmail.com",
    "Crypto Transfer": "USDT_TRC20 Address: 0x998f046a38402fa0c95d8fbbcb2ee51975b9d019",
    "Payoneer": "Payoneer Username: jimdonalds",
    "Payeer": "Payeer Account: p165735463",
    "Wise": "Wise Account: lucyakins@gmail.com",
    "AirTM": "AirTM Username: tomjefferson",
    "ABA Bank": "ABA Bank Account: ABA42697863",
    "Skrill": "Skrill Email: kevinhollard@skrill.com",
    "Bank of Georgia": "Account: 2001547434787 Bank of Georgia",
    "KHQR": "KHQR Code: 167452015651",
  };

  const handleProceed = () => {
    if (!amount || !paymentMethod) {
      setError("Please enter an amount and select a payment method.");
      return;
    }
    setError("");
    setShowDepositDetails(true);
  };

  const handleBack = () => {
    setShowDepositDetails(false);
    setAmount("");
    setPaymentMethod("");
  };

  const handleRefresh = () => {
    setTransactionStatus("Pending");
  };

  return (
    <Body>
      <Container>
        <Title>P2P Buy</Title>
        {!showDepositDetails ? (
          <>
            <InstructionText>
              Enter the amount of USDT you want to purchase and select a preferred
              payment method to proceed.
            </InstructionText>
            <FormGroup>
              <Label>Amount (USDT):</Label>
              <Input
                type="number"
                value={amount}
                onChange={(e) => setAmount(Math.max(0, e.target.value))} // Prevent negative numbers
                placeholder="Enter the amount of USDT"
              />
              {error && !amount && <ErrorMessage>Amount is required.</ErrorMessage>}
            </FormGroup>
            <FormGroup>
              <Label>Payment Method:</Label>
              <Select
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              >
                <option value="">Select Payment Method</option>
                {paymentMethods.map((method, index) => (
                  <option key={index} value={method}>
                    {method}
                  </option>
                ))}
              </Select>
              {error && !paymentMethod && (
                <ErrorMessage>Payment method is required.</ErrorMessage>
              )}
            </FormGroup>
            <Button onClick={handleProceed}>Proceed</Button>
          </>
        ) : (
          <>
            <InstructionText>
              Below are the detailed deposit instructions for your selected payment method. Please ensure that you follow the steps precisely to complete your transaction successfully.
            </InstructionText>
            <DepositDetails>
              <DetailsRow>
                <DetailsLabel>Deposit Instructions:</DetailsLabel>
                <DetailsValue>
                  {depositDetailsMap[paymentMethod] || "No details available."}
                </DetailsValue>
              </DetailsRow>
              <DetailsRow>
                <DetailsLabel>Transaction Reference:</DetailsLabel>
                <DetailsValue>{transactionReference}</DetailsValue>
              </DetailsRow>
            </DepositDetails>

            <Status>
              <StatusText>Status: {transactionStatus}</StatusText>
              <RefreshIcon onClick={handleRefresh} />
            </Status>

            <Button secondary onClick={handleBack}>
              Back
            </Button>
          </>
        )}
      </Container>
    </Body>
  );
};

export default P2PBuy;
