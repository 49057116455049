import React from "react";
import P2PList from "./P2P_List";
import P2PContent from "./P2P_Content";

const P2P = () => {
  return (
    <div>
      <P2PList />
      <P2PContent />
    </div>
  );
};

export default P2P;
